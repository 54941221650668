/* eslint-disable max-len,no-plusplus,no-param-reassign */
import Scroll from "../lib/Scroll";
class AnimateScroll extends Scroll {
    constructor() {
        super();
        this.ignoredHrefs = [];
        this.init();
    }
    init() {
        var _a;
        const animatedLinks = Array.from((_a = document.querySelectorAll('a[href^="#"]')) !== null && _a !== void 0 ? _a : []);
        if (animatedLinks.length) {
            animatedLinks.forEach((link) => {
                link.addEventListener("click", this.initScroll.bind(this));
            });
        }
        window.addEventListener("animate.scroll", (ev) => {
            let element = ev.detail;
            if (typeof element === "undefined") {
                return false;
            }
            if (!(element instanceof HTMLElement)) {
                element = document.querySelector(element);
            }
            if (element !== null) {
                this.scroll(element, 1250);
            }
            return true;
        });
    }
    initScroll(ev) {
        var _a;
        const target = ev.target;
        if (!target) {
            console.warn("[AnimateScroll] Href attribute undefined");
            return;
        }
        if (target.getAttribute("href") === "#") {
            console.warn("[AnimateScroll] not a hash");
            return;
        }
        const targetHref = (_a = target.getAttribute("href")) !== null && _a !== void 0 ? _a : "";
        if (document.querySelector(targetHref) === null) {
            console.warn(`[AnimateScroll] Referenced element does not exist - ${target.getAttribute("href")}`);
            return;
        }
        if (this.ignoredHrefs.indexOf(targetHref) !== -1) {
            console.warn("[AnimateScroll] in ignoredHrefs");
            return false;
        }
        ev.preventDefault();
        const targetEl = document.querySelector(targetHref);
        if (targetEl) {
            window.scrollTo({
                top: this.getOffsetTop(targetEl),
                behavior: "smooth",
            });
        }
    }
}
export default AnimateScroll;

/**
 * This class applies a given class to an element when the user scrolls past another element and removes it when the user scrolls to the footer.
 */
class AffixedCallToAction {
    constructor(cta) {
        this.headerSelector = ".section--header";
        this.footerSelector = ".footer";
        this.headerEl = null;
        this.footerEl = null;
        this.headerPosition = -1;
        this.footerPosition = 999999;
        this.cta = cta;
        this.init();
    }
    setHeaderSelector(value) {
        this.headerSelector = value;
    }
    setFooterSelector(value) {
        this.footerSelector = value;
    }
    init() {
        this.headerEl = document.querySelector(this.headerSelector);
        this.footerEl = document.querySelector(this.footerSelector);
        if (this.headerEl) {
            this.headerPosition = this.headerEl.offsetTop + this.headerEl.offsetHeight;
        }
        if (this.footerEl) {
            this.footerPosition = this.footerEl.offsetTop - window.innerHeight;
        }
        window.addEventListener("scroll", this.onScroll.bind(this));
    }
    onScroll() {
        const windowHeight = window.innerHeight;
        // console.log("[AffixedCallToAction] onScroll", window.scrollY, this.headerPosition, this.footerPosition);
        if (window.scrollY > this.headerPosition && window.scrollY < this.footerPosition) {
            this.cta.classList.add("is-active");
        }
        else {
            this.cta.classList.remove("is-active");
        }
    }
}
export default AffixedCallToAction;

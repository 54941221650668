/* eslint-disable max-len */
class Scroll {
    constructor() {
        this.easing = (t) => t * t;
        this.getOffsetTop = (element) => {
            const box = element.getBoundingClientRect();
            const docElem = document.documentElement;
            return box.top + window.scrollY - docElem.clientTop;
        };
    }
    scroll(destination, duration = 2000) {
        const start = window.scrollY;
        const startTime = 'now' in window.performance ? performance.now() : new Date().getTime();
        const documentHeight = Math.max(document.body.scrollHeight, document.body.offsetHeight, document.documentElement.clientHeight, document.documentElement.scrollHeight, document.documentElement.offsetHeight);
        const windowHeight = window.innerHeight || document.documentElement.clientHeight || document.getElementsByTagName('body')[0].clientHeight;
        const destinationOffset = typeof destination === 'number' ? destination : this.getOffsetTop(destination);
        const destinationOffsetToScroll = Math.round(documentHeight - destinationOffset < windowHeight ? documentHeight - windowHeight : destinationOffset) - 90;
        const easingChoice = this.easing;
        const direction = (start < destinationOffsetToScroll) ? 'down' : 'up';
        if (!('requestAnimationFrame' in window)) {
            this.moveTo(0, (destinationOffsetToScroll > 0) ? destinationOffsetToScroll : 0);
            return;
        }
        if (('requestAnimationFrame' in window)) {
            function scroll() {
                const now = 'now' in window.performance ? performance.now() : new Date().getTime();
                const time = Math.min(1, ((now - startTime) / duration));
                const timeFunction = easingChoice(time);
                window.scroll(0, Math.ceil((timeFunction * (destinationOffsetToScroll - start)) + start));
                if ((direction === 'down' && (window.scrollY >= (destinationOffsetToScroll - 50) || window.scrollY === 0))
                    || (direction === 'up' && (window.scrollY <= (destinationOffsetToScroll) || window.scrollY === 0))) {
                    window.dispatchEvent(new Event('animated-scroll-complete'));
                    return;
                }
                requestAnimationFrame(scroll);
            }
            setTimeout(() => {
                scroll();
            }, 1);
        }
    }
    /**
     * Added because typescript was complaining about window.scroll inside the typeof check in scroll()
     * Couldn't figure out why...
     *
     * @param x
     * @param y
     */
    moveTo(x, y) {
        window.scroll(x, y);
        return;
    }
}
export default Scroll;
